import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
import CandidateJobBoardCard from "../../components/CandidateJobBoardCard";
import useJobs from "../../hooks/jobs/useJobs";
import useCandidateMatchesStats from "../../hooks/jobs/useCandidateMatchesStats";

export const TABS = {
  Matches: "matches",
  Liked: "savedby",
  Applied: "applicants",
  Rejected: "rejectedby",
  Likes: "likes",
};

const getQueryBasedOnTab = (userId, activeTab) => {
  switch (activeTab) {
    case TABS.Matches:
      return { userId, queryField: TABS.Matches };
    case TABS.Liked:
      return { userId, queryField: TABS.Liked };
    case TABS.Applied:
      return { userId, queryField: TABS.Applied };
    case TABS.Rejected:
      return { userId, queryField: TABS.Rejected };
    case TABS.Likes:
      return { userId, queryField: TABS.Likes };
    default:
      return {};
  }
};

const CandidateMatches = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(state?.defaultTab ?? TABS.Matches);
  const { countJobs, refetchStats } = useCandidateMatchesStats();
  const { jobs, isDataLoading, isFetching, refetch } = useJobs(
    getQueryBasedOnTab(userData.uid, activeTab)
  );

  const tabClass = (tabKey) => {
    return tabKey === activeTab
      ? "text-[#46D6EA] border-b-[3px] border-[#46D6EA] pb-2 font-bold"
      : "text-white";
  };
  const handleTabSelect = (key) => {
    setActiveTab(key);
    refetchStats();
  };

  const handleJobClick = (jobId, activeTab) => () => {
    navigate(`/job-details-page/${jobId}`, {
      state: { tab: activeTab },
    });
  };

  return (
    <div className="flex justify-center text-white">
      <div className="flex flex-column items-center my-1 md:my-10 w-screen">
        <div className="w-[90%] md:w-[70%] mb-3 mt-8">
          <h1 className="font-bold text-start text-xl">My Overview</h1>
        </div>
        <div className="w-[90%] md:w-[70%] my-2">
          <div>
            <Tabs
              defaultActiveKey={activeTab}
              variant=""
              // id="fill-tab-example"
              id="noanim-tab-example"
              //   transition={false}
              className="flex flex-col md:flex-row mb-3 border-none ml-[-14px]"
              onSelect={handleTabSelect}
              // fill
            >
              <Tab
                eventKey={TABS.Matches}
                title={
                  <p className="text-white">
                    <span className={`pt-4 mr-3 ${tabClass(TABS.Matches)}`}>
                      My Matches
                    </span>
                    ({countJobs?.matched ?? 0})
                  </p>
                }
              >
                {TABS.Matches === activeTab && (
                  <>
                    <p>
                      It's a match! You can choose to apply or ask further
                      questions via your{" "}
                      <Link to="/chat-room" className="underline">
                        Messages
                      </Link>
                      .
                    </p>
                    <div className="flex flex-column mt-2">
                      {/* Jobs parts */}
                      <div className="mt-3">
                        {/* Jobs cards container */}
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 auto-rows-max grid-auto-column">
                          {/* Job card */}
                          {isDataLoading || isFetching ? (
                            <DotLoader color="#868bff" />
                          ) : (
                            jobs?.map((job) => (
                              <CandidateJobBoardCard
                                key={job.uid}
                                position={job.position}
                                job={job}
                                origin={"matches"}
                                queryObject={getQueryBasedOnTab(
                                  userData.uid,
                                  activeTab
                                )}
                                refetchJobs={refetch}
                                onClick={handleJobClick(job.uid, TABS.Matches)}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Tab>
              <Tab
                eventKey={TABS.Liked}
                title={
                  <p className="text-white">
                    <span className={`pt-4 mr-3 ${tabClass(TABS.Liked)}`}>
                      My Likes
                    </span>
                    ({countJobs?.saved ?? 0})
                  </p>
                }
              >
                {TABS.Liked === activeTab && (
                  <>
                    <p>
                      These jobs have caught your eye. Review the jobs and
                      decide whether to apply, ask further questions, or undo
                      your like.
                    </p>
                    <div className="flex flex-column mt-2">
                      {/* Jobs parts */}
                      <div className="mt-3">
                        {/* jobs cards container */}
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 auto-rows-max grid-auto-column">
                          {/* job card */}
                          {isDataLoading || isFetching ? (
                            <DotLoader color="#868bff" />
                          ) : (
                            jobs?.map((job) => (
                              <CandidateJobBoardCard
                                key={job.uid}
                                position={job.position}
                                job={job}
                                queryObject={getQueryBasedOnTab(
                                  userData.uid,
                                  activeTab
                                )}
                                refetchJobs={refetch}
                                onClick={handleJobClick(job.uid, TABS.Liked)}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Tab>
              <Tab
                eventKey={TABS.Likes}
                title={
                  <p className="text-white">
                    <span className={`pt-4 mr-3  ${tabClass(TABS.Likes)}`}>
                      Likes Me
                    </span>
                    ({countJobs?.likedByJobs ?? 0})
                  </p>
                }
              >
                {TABS.Likes === activeTab && (
                  <>
                    <p>
                      These law firms are interested in your profile. Is it a
                      match? You can like them back, apply, ask further
                      questions, or indicate that you're not interested. Your
                      profile will remain anonymous.
                    </p>
                    <div className="flex flex-column mt-2 relative">
                      {/* Jobs parts */}
                      <div className="mt-3">
                        {/* jobs cards container */}
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 auto-rows-max grid-auto-column">
                          {/* job card */}
                          {isDataLoading || isFetching ? (
                            <DotLoader color="#868bff" />
                          ) : (
                            jobs?.map((job) => (
                              <CandidateJobBoardCard
                                key={job.uid}
                                position={job.position}
                                job={job}
                                queryObject={getQueryBasedOnTab(
                                  userData.uid,
                                  activeTab
                                )}
                                refetchJobs={refetch}
                                onClick={handleJobClick(job.uid, TABS.Likes)}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Tab>
              <Tab
                eventKey={TABS.Rejected}
                title={
                  <p className="text-white">
                    <span className={`pt-4 mr-3 ${tabClass(TABS.Rejected)}`}>
                      Not Interested
                    </span>
                    ({countJobs?.rejected ?? 0})
                  </p>
                }
              >
                {TABS.Rejected === activeTab && (
                  <>
                    <p>
                      Jobs that don't spark joy. You can always review your
                      decision by pressing 'Undo Not Interested'. Law firms are
                      not notified if you list a job as 'Not Interested'.
                    </p>
                    <div className="flex flex-column mt-2 relative">
                      {/* Jobs parts */}
                      <div className="mt-3">
                        {/* jobs cards container */}
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 auto-rows-max grid-auto-column">
                          {/* job card */}
                          {isDataLoading || isFetching ? (
                            <DotLoader color="#868bff" />
                          ) : (
                            jobs?.map((job) => (
                              <CandidateJobBoardCard
                                key={job.uid}
                                position={job.position}
                                job={job}
                                queryObject={getQueryBasedOnTab(
                                  userData.uid,
                                  activeTab
                                )}
                                refetchJobs={refetch}
                                onClick={handleJobClick(job.uid, TABS.Rejected)}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Tab>
              <Tab
                eventKey={TABS.Applied}
                title={
                  <p className="text-white">
                    <span className={`pt-4 mr-3 ${tabClass(TABS.Applied)}`}>
                      My Applications
                    </span>
                    ({countJobs?.applications ?? 0})
                  </p>
                }
              >
                {TABS.Applied === activeTab && (
                  <>
                    <p>These are the jobs you've applied for.</p>
                    <div className="flex flex-column mt-2">
                      {/* Jobs parts */}
                      <div className="mt-3">
                        {/* candidates cards container */}
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 auto-rows-max grid-auto-column">
                          {/* Job card */}

                          {isDataLoading || isFetching ? (
                            <DotLoader color="#868bff" />
                          ) : (
                            jobs?.map((job) => (
                              <CandidateJobBoardCard
                                key={job.uid}
                                position={job.position}
                                job={job}
                                origin={"applied"}
                                queryObject={getQueryBasedOnTab(
                                  userData.uid,
                                  activeTab
                                )}
                                refetchJobs={refetch}
                                onClick={handleJobClick(job.uid, TABS.Applied)}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateMatches;
