import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";
import TinderCard from "react-tinder-card";
import CandidateJobBoardCard from "../../components/CandidateJobBoardCard";
import SearchBar from "../../components/SearchBar.jsx";
import useJobs from "../../hooks/jobs/useJobs";
import useLikeJob from "../../hooks/jobs/useLikeJob";
import useToggleRejectJob from "../../hooks/jobs/useToggleRejectJob";

const SearchPage = () => {
  const userData = useSelector((state) => state.user.userData);
  const [filters, setFilters] = useState({});
  const queryObject = { ...filters, userId: userData.uid };
  const { jobs, isDataLoading, isFetching, refetch } = useJobs(queryObject);
  const { likeJob } = useLikeJob();
  const { toggleRejectJob } = useToggleRejectJob();

  const isFiltered = Object.values(filters).some((filter) => {
    if (Array.isArray(filter)) {
      return filter.length !== 0;
    } else if (typeof filter == "boolean") {
      return filter;
    }

    return false;
  });

  const onSwipe = (direction, job) => {
    switch (direction) {
      case "right":
        likeJob({
          userId: userData.uid,
          jobId: job.uid,
          jobUserId: job.userid,
          queryObject: queryObject,
        });
        return;
      case "left":
        toggleRejectJob({
          userId: userData.uid,
          jobId: job.uid,
          queryObject: queryObject,
        });
        return;
      default:
        return;
    }
  };

  return (
    <div className="flex justify-center text-white">
      <div className="flex flex-column items-center my-1 md:my-10 w-screen">
        <div className="flex flex-column  mb-2 mt-8 md:mt-8 w-[90%] lg:w-[80%]">
          <div className="flex flex-col md:flex-row md:items-center space-between">
            <div className="flex flex-col mr-8 pb-4 md:pb-0 gap-3 flex-1">
              <p className="font-bold text-lg">
                Welcome to your personalised Job Board!
              </p>
              <p className="text-sm">
                Each job's score (%) reflects how well it matches your profile.
                Don't worry, no actions are permanent - except an application.
                All jobs remain accessible under{" "}
                <Link to="/my-matches" className="underline">
                  My Overview
                </Link>
                .
              </p>
            </div>
            <div className="flex">
              <SearchBar
                isFiltered={isFiltered}
                onSearch={setFilters}
                onClear={setFilters}
                userData={userData}
              />

              <span className="ml-3 pt-2">
                {jobs?.length} {isFiltered && <>filtered </>} results
              </span>
            </div>
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 mt-1 md:mt-3 auto-rows-max grid-auto-column">
            {isDataLoading || isFetching ? (
              <DotLoader color="#868bff" />
            ) : jobs.length === 0 ? (
              isFiltered ? (
                <>No results for these filters</>
              ) : (
                <>
                  No ideal results found. Consider expanding your search beyond
                  your current preferences.
                </>
              )
            ) : (
              jobs.map((job) => (
                <div key={job.uid} className="flex flex-col h-full">
                  <div className="block sm:hidden h-full">
                    <TinderCard
                      className="pressable absolute left-0 w-full h-full max-h-[520px]"
                      onSwipe={(direction) => onSwipe(direction, job)}
                      preventSwipe={["up", "down"]}
                      preventMove={["yAxis"]}
                      swipeThreshold={100}
                      swipeRequirementType="position"
                    >
                      <div className="p-[20px] h-full">
                        <CandidateJobBoardCard
                          job={job}
                          queryObject={queryObject}
                          className="h-full"
                          refetchJobs={refetch}
                        />
                      </div>
                    </TinderCard>
                  </div>
                  <div className="hidden sm:block h-full mt-4">
                    <CandidateJobBoardCard
                      job={job}
                      queryObject={queryObject}
                      className="h-full"
                      refetchJobs={refetch}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
