import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// @ts-ignore
import App from "./App.jsx";
// @ts-ignore
import { store } from "./app/store";
import "./index.css";
import { StrictMode } from "react";
import Providers from "./context/Providers";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Providers>
          <App />
        </Providers>
      </BrowserRouter>
    </Provider>
  </StrictMode>
);
