import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { HiQuestionMarkCircle } from "react-icons/hi2";
import harvey from "../../assets/example_images/harveyold1.jpg";
import anonymousIcon from "../../assets/icons/anonymous.png";
import ExperienceList from "../../components/ExperienceList";
import EditCandidate from "../../components/modals/EditCandidate/EditCandidate";
import { IMAGES } from "../../constants/images";
import { experienceTotal } from "../../utils/helpers";
import styles from "./UserProfile.module.css";

const UserProfile = () => {
  const user = useSelector((state) => state.data.value);
  const [isShowEditModal, setIsShowEdiModal] = useState(false);

  const handleCloseEditModal = () => setIsShowEdiModal(false);
  const handleShowEditModal = () => setIsShowEdiModal(true);

  const navigate = useNavigate();

  const flipDateString = (dateString) => {
    const parts = dateString.split("-");
    const flippedDateString = parts.reverse().join("-");
    return flippedDateString;
  };

  return (
    <div className={styles.container}>
      <div
        className={
          (styles.contentsection,
          "flex flex-col md:flex-row items-center space-between gap-2 w-[90%] lg:w-[80%] mt-5 gap-4")
        }
      >
        <div className="flex flex-col md:flex-row  items-center gap-2">
          <h1 className={`${styles.titletext} "text-lefttext-white`}>
            My Profile
          </h1>
          <div className="flex flex-col flex-row items-center">
            <Button
              className="flex items-center text-[#1F2F42] bg-[#46D6EA] px-3 py-1 mt-1"
              onClick={() => navigate("/me/preview")}
            >
              <img
                src={anonymousIcon}
                width="15"
                height="15"
                className="text-black"
              />
              Preview in anonymous mode
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip
                    placement="bottom"
                    className="in"
                    id="tooltip-bottom"
                  >
                    This is how your profile will appear to law firms by
                    default. Only when you apply or choose to reveal your full
                    profile will they access all your information.
                  </Tooltip>
                }
              >
                <p className="text-black">
                  <HiQuestionMarkCircle />
                </p>
              </OverlayTrigger>
            </Button>
          </div>
        </div>
        <Button variant="outlined" onClick={handleShowEditModal}>
          <img
            src={IMAGES.EDIT}
            width="15"
            height="15"
            className="text-black"
          />
          Edit profile
        </Button>
      </div>
      <div
        className={
          (styles.contentsection,
          "flex flex-col md:flex-row items-start justify-center w-[90%] lg:w-[80%] mt-5 gap-4")
        }
      >
        <div className="flex flex-col gap-4 w-full md:max-w-[458px]">
          <div className={styles.topbar}>
            {user?.profileImg ? (
              <>
                <img
                  src={user.profileImg}
                  alt="user image"
                  className={styles.imgprofile}
                />
              </>
            ) : (
              <>
                <img
                  src={harvey}
                  alt="user image"
                  className={styles.imgprofile}
                />
              </>
            )}

            <div className={styles.widesection}>
              <div className="flex justify-between">
                <div className="flex flex-col items-center justify-center gap-4">
                  {user?.name == "" ? (
                    <p className="bigText lowercase">{user?.email}</p>
                  ) : (
                    <p className="font-bold text-xl text-white">
                      {user?.name} {user?.surname}
                    </p>
                  )}
                  <button className={styles.greenbtn}>
                    <div className={styles.thicktextbtn}>
                      •{" "}
                      {user?.searchingStatus
                        ? user?.searchingStatus
                        : "Casually exploring"}
                    </div>
                  </button>
                </div>
              </div>
              <div className={styles.topbarsection}>
                {user?.cities.length > 0 ? (
                  <p className="flex flex-row gap-y-1 items-center smallText">
                    <CiLocationOn size={20} className="mr-2" />

                    {user?.cities.join(", ")}
                  </p>
                ) : (
                  <p className="flex flex-row gap-y-1  items-center smallText">
                    <CiLocationOn size={20} className="mr-2" />
                    No location added
                  </p>
                )}
                <div
                  className={`${styles.text} flex flex-row gap-y-1 items-center smallText`}
                >
                  <MdOutlineMenu size={20} className="mr-2" />
                  {user?.expertise ? (
                    user?.expertise[0] && <>{user?.expertise?.join(" | ")}</>
                  ) : (
                    <>
                      <p>No expertise is added</p>
                    </>
                  )}
                </div>
                <div
                  className={`${styles.text} flex flex-row gap-y-1 items-center smallText`}
                >
                  <PiSuitcaseLight size={20} className="mr-2" />
                  <span>{experienceTotal(user)}</span>
                </div>
                <div
                  className={`${styles.text} flex flex-row gap-y-1 items-center smallText`}
                >
                  <BiMessage size={20} className="mr-2" />
                  {user?.selectedLanguages ? (
                    user?.selectedLanguages[0] && (
                      <>{user?.selectedLanguages.join(" | ")}</>
                    )
                  ) : (
                    <>
                      <p>No language is added</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={"flex flex-col gap-4"}>
            <div className={styles.block}>
              <h2 className={styles.titletext}>Skills</h2>
              <div className="flex flex-col flex-wrap gap-2">
                {user?.skills?.length > 0 ? (
                  user?.skills?.map((skill, i) => (
                    <button className={styles.buttonSkill} key={i}>
                      <img src={IMAGES.CHECK_MARK} width={15} height={14} />
                      {skill}
                    </button>
                  ))
                ) : (
                  <>No skills added</>
                )}
              </div>
            </div>
            <div className={styles.block}>
              <div className="flex items-center justify-between">
                <h2 className={styles.titletext}>Documents</h2>
              </div>
              <div>
                {user?.cv != null && user?.cv !== "" ? (
                  <>
                    <div className={styles.cv_element}>
                      <div className="flex gap-4 text-sm font-medium">
                        <img src={IMAGES.ATTACHMENT} width={15} height={14} />
                        {user.name}-CV
                      </div>
                      <a
                        className={styles.buttonView}
                        href={user.cv}
                        target="_blank"
                      >
                        <img
                          src={IMAGES.ATTACHMENT_VIEW}
                          width={15}
                          height={15}
                        />
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-2 text-white text-sm font-medium">
                      No CV is uploaded
                    </div>
                  </>
                )}
              </div>
              <div>
                {user?.transcripts != null &&
                user?.transcripts !== "" &&
                user.transcripts.length > 0 ? (
                  <>
                    {user.transcripts?.map((path, index) => (
                      <div className={styles.cv_element} key={index}>
                        <div className="flex gap-4 text-sm font-medium">
                          <img src={IMAGES.ATTACHMENT} width={15} height={14} />
                          {user?.name}-Transcript-{index + 1}
                        </div>
                        <a
                          className={styles.buttonView}
                          href={path}
                          target="_blank"
                        >
                          <img
                            src={IMAGES.ATTACHMENT_VIEW}
                            width={15}
                            height={15}
                          />
                        </a>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="mt-2 text-white text-sm font-medium">
                      No Academic Transcripts are uploaded
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={styles.block}>
              <h2 className={styles.titletext}>
                Additional matchmaking criteria
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      placement="bottom"
                      className="in"
                      id="tooltip-bottom"
                    >
                      Don't worry, this information is only used by the
                      matchmaking algorithm. Law firms will never see this, not
                      even if you put your profile on public.
                    </Tooltip>
                  }
                >
                  <p className="ml-2 text-[#fff]">
                    <HiQuestionMarkCircle />
                  </p>
                </OverlayTrigger>
              </h2>
              {user?.annualRemuneration != null &&
                user.annualRemuneration != "" && (
                  <>
                    <div>
                      <h3 className={"font-medium text-lg text-white"}>
                        Preferred Remuneration
                      </h3>
                      <div className={styles.buttonSkill}>
                        <span className="font-bold">
                          {user.annualRemuneration}
                        </span>
                      </div>
                    </div>
                    <div className=" border-b-[1px] border-grey" />
                  </>
                )}
              <div className="flex flex-col flex-wrap gap-2">
                <h3 className={"flex gap-2 font-medium text-lg text-white"}>
                  <img src={IMAGES.LIKE} width={15} height={15} />
                  Law firms you'd like to follow
                </h3>
                {user?.tomatch?.map((firm, i) => {
                  if (typeof firm === "string") {
                    return (
                      <div className="text-white text-sm" key={i}>
                        {firm}
                      </div>
                    );
                  } else {
                    return (
                      <div className="text-white text-sm" key={i}>
                        {firm.value}
                      </div>
                    );
                  }
                })}
              </div>
              <div className=" border-b-[1px] border-grey" />
              <div className="flex flex-col flex-wrap gap-2">
                <h3 className={"flex gap-2 font-medium text-lg text-white"}>
                  <img src={IMAGES.DISLIKE} width={15} height={15} />
                  Law firms you'd rather not be matched with
                </h3>
                {user?.nottomatch?.map((firm, i) => {
                  if (typeof firm === "string") {
                    return (
                      <div className="text-white text-sm" key={i}>
                        {firm}
                      </div>
                    );
                  } else {
                    return (
                      <div className="text-white text-sm" key={i}>
                        {firm.value}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            (styles.personalmidsection, "flex flex-col lg:flex-row w-full ")
          }
        >
          <div className={styles.largesection}>
            <div className={styles.abtme}>
              <div className="flex justify-between mb-3 mt-3">
                <h2 className={styles.titletext}>About Me</h2>
              </div>
              {user?.description ? (
                user?.description.split("\n").map((str, index) => (
                  <p className="text-white mb-2" key={index}>
                    {str}
                  </p>
                ))
              ) : (
                <>
                  <p className="text-white">No description is added</p>
                </>
              )}
            </div>

            <div className={styles.abtme}>
              <div className="flex items-center justify-between mb-3">
                <h2 className={styles.titletext}>Experience</h2>
              </div>
              <ExperienceList
                experiences={user.employmentHistory}
                isIncognito={false}
              />
            </div>

            <div className={"flex flex-col gap-4 pt-[24px] border-none"}>
              <div className="flex justify-between">
                <h2 className={styles.titletext}>My Education</h2>
              </div>
              <div>
                {user?.master && user.master.length > 0 ? (
                  <>
                    <p className="text-white  mt-2 font-bold">
                      Master of Laws (LL.M.) Certificates
                    </p>
                    {user.master?.map((item, index) => (
                      <div className="flex items-center" key={index}>
                        <ul className="px-4 list-disc">
                          <li className="text-white">{item}</li>
                        </ul>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <p className="text-white">No LL.M. added</p>
                  </>
                )}
              </div>
              <div>
                {user?.university ? (
                  <>
                    <div className="flex flex-col items-start  justify-between">
                      <p className="text-white">
                        University Name: {user.university}
                      </p>
                      <p className="text-white"> - Grades: {user.grades}</p>
                      <p className="text-white">
                        - Graduation date:{" "}
                        {flipDateString(user.dateOfGradutation)}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-white">No Education is added </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditCandidate
        show={isShowEditModal}
        handleClose={handleCloseEditModal}
      />
    </div>
  );
};

export default UserProfile;
